import React from "react";
import "../Main/Loading.scss";
export default function Loading() {
  return (
    <div className="App-header white">
      <div className="scan">
        <div className="fingerprint">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="412.000000pt"
            height="412.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#fff"
              stroke="#fff"
              strokeWidth={50}
            >
                <path fill="none" className="is-active"
                d="M2505 5108 c-164 -18 -376 -80 -530 -154 -168 -80 -359 -217 -477
-342 -61 -64 -68 -83 -42 -120 26 -38 64 -29 125 29 217 207 411 327 644 398
187 57 301 72 544 75 l134 1 21 28 c19 26 19 30 5 55 -9 16 -27 30 -44 33 -44
10 -288 8 -380 -3z"
              />
                <path fill="none" className="is-active"
                d="M2665 4929 c-142 -11 -242 -32 -390 -80 -135 -44 -137 -45 -154 -77
-17 -31 5 -76 39 -80 14 -2 74 13 132 33 175 58 274 76 463 82 199 6 275 -7
435 -77 154 -66 335 -182 397 -254 27 -31 65 -34 93 -6 34 34 25 60 -44 126
-152 145 -446 294 -636 323 -108 16 -219 19 -335 10z"
              />
               <path fill="none" className="is-active"
                d="M2625 4713 c-471 -61 -896 -363 -1276 -908 -156 -223 -242 -437 -303
-750 -27 -141 -37 -235 -46 -460 -6 -121 -17 -260 -25 -309 -14 -80 -14 -91 0
-112 29 -44 98 -25 110 29 16 73 26 176 35 362 16 329 51 550 122 760 51 152
97 242 197 391 112 167 197 271 336 410 281 281 562 428 886 464 108 12 181 8
319 -16 205 -37 387 -144 505 -299 151 -198 223 -470 195 -732 -13 -116 -13
-117 10 -135 48 -39 93 -10 107 69 4 26 7 118 7 203 -1 138 -5 168 -32 275
-98 378 -345 633 -702 725 -136 36 -321 49 -445 33z"
              />
               <path fill="none" className="is-active"
                d="M1860 4648 c-295 -202 -606 -560 -798 -918 -62 -115 -136 -312 -153
-403 -11 -61 -11 -68 6 -87 21 -23 67 -26 88 -5 8 8 23 47 32 87 46 190 156
416 299 613 185 257 408 485 601 615 88 60 106 87 81 125 -27 40 -68 33 -156
-27z"
              />
               <path fill="none" className="is-active"
                d="M2670 4494 c-14 -2 -52 -9 -85 -15 -166 -28 -355 -113 -528 -236
-115 -81 -336 -305 -441 -446 -288 -386 -388 -666 -416 -1159 -19 -341 -33
-423 -94 -532 -40 -71 -54 -85 -116 -116 -25 -12 -53 -29 -62 -38 -24 -21 -23
-67 3 -93 29 -29 85 -21 158 24 100 62 173 180 209 342 27 120 30 149 42 370
15 296 40 440 104 618 62 171 155 332 305 527 97 127 310 335 419 408 232 158
472 228 700 202 207 -23 314 -69 424 -182 54 -56 91 -63 126 -25 30 31 28 57
-8 102 -88 111 -235 193 -413 231 -72 15 -279 27 -327 18z"
              />
               <path fill="none" className="is-active"
                d="M3754 4294 c-16 -15 -24 -32 -21 -42 3 -9 24 -69 46 -132 80 -229 98
-428 61 -685 -32 -226 -24 -287 41 -321 74 -38 252 -22 275 25 15 29 7 59 -21
77 -18 12 -32 12 -73 4 -28 -6 -66 -10 -84 -8 -29 3 -33 7 -36 37 -2 18 4 78
13 133 38 225 35 402 -11 606 -47 209 -102 332 -149 332 -9 0 -27 -12 -41 -26z"
              />
               <path fill="none" className="is-active"
                d="M2642 4230 c-87 -23 -258 -106 -363 -177 -224 -152 -431 -360 -571
-578 -93 -144 -198 -380 -198 -446 0 -37 39 -79 74 -79 37 0 72 38 91 100 27
85 117 266 180 360 188 280 457 510 741 636 86 38 93 39 199 39 102 0 114 -2
170 -30 73 -37 164 -124 198 -191 45 -90 60 -157 55 -264 -3 -88 -7 -104 -41
-172 -47 -97 -140 -185 -342 -329 -248 -176 -322 -256 -373 -403 -21 -61 -24
-83 -20 -161 9 -156 58 -227 302 -437 173 -149 209 -184 275 -272 125 -165
189 -348 198 -568 15 -369 -120 -701 -433 -1066 -55 -63 -74 -93 -74 -114 0
-40 40 -78 81 -78 37 0 83 44 193 185 314 402 442 811 381 1218 -47 315 -218
580 -532 826 -78 61 -192 180 -214 222 -13 27 -19 59 -19 107 0 138 75 233
320 408 258 183 336 259 401 391 81 163 78 386 -8 563 -46 94 -168 220 -258
267 -123 64 -274 79 -413 43z"
              />
               <path fill="none" className="is-active"
                d="M3435 4079 c-32 -26 -31 -43 14 -169 99 -281 44 -570 -153 -799 -60
-70 -105 -108 -371 -311 -75 -58 -159 -122 -186 -143 -54 -41 -62 -69 -31
-107 31 -38 63 -29 169 49 54 39 114 84 133 99 19 15 85 65 145 112 240 186
340 306 414 499 79 204 81 419 8 641 -31 94 -68 150 -100 150 -8 0 -27 -10
-42 -21z"
              />
               <path fill="none" className="is-active"
                d="M2685 3951 c-229 -83 -479 -271 -662 -497 -166 -204 -289 -485 -323
-741 -20 -144 -8 -373 25 -503 59 -234 197 -447 402 -620 246 -208 282 -240
309 -276 26 -34 29 -45 29 -113 -1 -95 -39 -194 -123 -319 -74 -111 -272 -315
-402 -417 -138 -107 -160 -129 -160 -158 0 -37 32 -70 67 -70 73 0 434 323
578 517 66 90 115 178 151 271 23 59 27 86 28 175 1 92 -2 110 -23 149 -32 61
-98 129 -221 229 -177 144 -247 210 -315 295 -105 131 -173 285 -200 452 -80
493 140 992 585 1324 104 78 274 169 332 178 61 9 124 -14 163 -60 102 -119
56 -281 -95 -337 -33 -12 -97 -47 -143 -78 -247 -166 -422 -406 -472 -648 -19
-94 -19 -270 0 -353 31 -128 117 -246 275 -376 219 -180 267 -224 322 -294
106 -132 155 -265 164 -444 7 -135 9 -142 43 -157 37 -15 67 -6 88 26 13 21
15 43 9 136 -9 143 -29 233 -76 334 -74 161 -171 274 -370 434 -275 221 -332
308 -332 513 0 210 89 406 264 579 90 89 176 149 289 202 109 51 156 94 195
181 37 82 39 192 4 280 -63 160 -246 244 -405 186z"
              />
               <path fill="none" className="is-active"
                d="M2733 3701 c-189 -69 -459 -297 -597 -505 -146 -220 -210 -427 -209
-676 1 -180 22 -276 89 -415 69 -141 151 -231 389 -426 156 -128 192 -165 240
-244 47 -79 57 -120 56 -234 0 -119 -18 -188 -80 -316 -95 -193 -252 -380
-493 -586 -123 -106 -128 -111 -128 -148 0 -31 6 -44 31 -65 56 -48 81 -36
274 134 230 203 413 434 495 629 64 150 75 205 75 361 -1 132 -3 151 -27 212
-57 145 -141 241 -368 424 -215 173 -310 297 -356 464 -28 98 -25 336 4 448
81 310 331 614 621 754 112 54 131 72 131 118 0 71 -63 101 -147 71z"
              />
               <path fill="none" className="is-active"
                d="M4034 3482 c-16 -10 -42 -155 -33 -179 16 -42 71 -35 89 12 17 45 23
144 10 160 -13 16 -47 20 -66 7z"
              />
               <path fill="none" className="is-active"
                d="M3674 3340 c-35 -13 -44 -36 -51 -117 -8 -104 13 -169 74 -234 63
-67 115 -83 268 -83 93 0 127 4 152 17 41 22 51 58 28 93 -16 26 -19 26 -79
18 -80 -12 -186 -9 -231 6 -20 6 -47 27 -63 48 -25 33 -27 42 -27 131 0 53 -3
101 -7 107 -11 16 -40 22 -64 14z"
              />
               <path fill="none" className="is-active"
                d="M3445 2995 c-14 -13 -25 -31 -25 -40 0 -53 99 -175 180 -223 68 -39
178 -66 280 -66 211 -2 336 60 289 142 -23 41 -57 49 -126 26 -86 -28 -238
-26 -317 5 -63 25 -129 78 -151 122 -34 65 -86 79 -130 34z"
              />
               <path fill="none" className="is-active"
                d="M1480 2802 c-29 -24 -43 -131 -43 -342 -1 -229 26 -347 124 -542 85
-172 211 -319 484 -569 95 -86 153 -154 161 -185 25 -97 -214 -346 -575 -599
-86 -60 -103 -87 -82 -131 16 -36 55 -48 94 -29 53 26 295 210 412 314 183
161 284 305 293 415 8 101 -47 174 -318 422 -245 224 -372 419 -430 658 -29
119 -34 227 -20 407 l14 162 -23 18 c-29 24 -62 24 -91 1z"
              />
               <path fill="none" className="is-active"
                d="M3251 2794 c-28 -36 -27 -47 14 -113 76 -122 166 -195 289 -234 102
-32 294 -30 456 6 184 40 214 60 196 131 -10 42 -50 49 -141 27 -211 -52 -375
-59 -484 -22 -81 28 -118 56 -172 133 -24 34 -52 69 -61 80 -27 27 -72 23 -97
-8z"
              />
               <path fill="none" className="is-active"
                d="M3055 2605 c-14 -13 -25 -32 -25 -41 0 -55 140 -202 265 -278 76 -46
191 -96 222 -96 30 0 63 37 63 70 0 38 -20 55 -103 90 -125 52 -204 114 -318
249 -33 38 -70 41 -104 6z"
              />
               <path fill="none" className="is-active"
                d="M2810 2480 c-32 -32 -25 -72 22 -131 163 -206 391 -364 608 -420 97
-26 347 -36 487 -20 184 20 261 63 234 130 -18 43 -56 55 -109 37 -113 -40
-444 -47 -577 -12 -122 33 -306 139 -414 240 -18 17 -64 68 -103 114 -58 69
-74 82 -98 82 -17 0 -39 -9 -50 -20z"
              />
               <path fill="none" className="is-active"
                d="M4045 2340 c-81 -22 -215 -40 -296 -40 -41 0 -60 -5 -72 -18 -22 -24
-21 -65 1 -85 26 -24 159 -22 298 3 155 28 194 41 218 73 19 26 19 30 5 55
-19 34 -59 38 -154 12z"
              />
               <path fill="none" className="is-active"
                d="M1343 2104 c-7 -3 -34 -44 -60 -92 -66 -118 -130 -184 -233 -241 -50
-28 -90 -57 -100 -73 -13 -25 -13 -29 7 -55 33 -44 90 -39 184 17 126 75 204
162 283 317 32 65 32 77 1 108 -22 23 -54 30 -82 19z"
              />
               <path fill="none" className="is-active"
                d="M3317 1859 c-27 -16 -39 -55 -25 -85 15 -31 61 -55 153 -80 109 -29
405 -26 535 4 106 26 130 41 130 86 0 37 -7 49 -38 65 -19 10 -38 8 -110 -11
-76 -20 -110 -23 -267 -23 -164 1 -185 3 -240 24 -75 28 -115 34 -138 20z"
              />
               <path fill="none" className="is-active"
                d="M1439 1807 c-13 -13 -53 -56 -88 -96 -77 -88 -172 -161 -267 -206
-74 -35 -84 -47 -84 -95 0 -31 39 -70 69 -70 31 0 165 71 237 125 39 29 112
99 163 154 83 91 91 104 91 140 0 30 -6 44 -22 55 -32 23 -71 20 -99 -7z"
              />
               <path fill="none" className="is-active"
                d="M4026 1624 c-12 -11 -16 -36 -16 -90 0 -61 4 -78 20 -94 24 -24 29
-24 58 -4 19 13 22 24 22 90 0 61 -4 78 -20 94 -24 24 -43 25 -64 4z"
              />
               <path fill="none" className="is-active"
                d="M1541 1558 c-141 -160 -202 -212 -314 -266 -58 -28 -111 -60 -117
-71 -31 -59 9 -131 72 -131 66 0 250 103 346 193 71 68 181 203 188 233 9 34
-12 79 -42 93 -52 23 -75 15 -133 -51z"
              />
               <path fill="none" className="is-active"
                d="M3463 1594 c-20 -21 -24 -31 -19 -57 46 -226 25 -617 -45 -830 -34
-105 -123 -279 -174 -342 -41 -49 -44 -83 -12 -113 53 -50 98 -21 183 115 153
243 222 546 211 923 -5 206 -17 274 -54 308 -32 29 -58 28 -90 -4z"
              />
               <path fill="none" className="is-active"
                d="M3675 1590 c-17 -19 -18 -26 -5 -103 23 -134 18 -435 -9 -582 -38
-199 -38 -200 -6 -221 20 -13 33 -15 53 -8 33 11 44 42 74 203 19 99 23 150
23 346 0 306 -18 385 -85 385 -15 0 -35 -9 -45 -20z"
              />
               <path fill="none" className="is-active"
                d="M3861 1581 c-7 -13 -5 -53 6 -132 16 -114 15 -165 -14 -381 -4 -31
-1 -39 21 -53 24 -16 28 -16 50 -1 33 21 46 83 53 251 6 149 -7 274 -33 312
-18 29 -69 31 -83 4z"
              />
               <path fill="none" className="is-active"
                d="M1757 1407 c-12 -13 -55 -62 -94 -110 -89 -106 -179 -181 -285 -238
-104 -56 -138 -88 -138 -129 0 -37 47 -90 79 -90 32 0 166 70 256 135 87 62
200 174 276 274 59 78 66 126 23 160 -36 28 -89 27 -117 -2z"
              />
               <path fill="none" className="is-active"
                d="M1921 1178 c-140 -163 -299 -299 -443 -381 -91 -52 -110 -114 -52
-164 38 -34 71 -28 167 28 172 103 292 205 423 362 64 76 74 93 74 127 0 48
-35 80 -88 80 -30 0 -42 -8 -81 -52z"
              />
               <path fill="none" className="is-active"
                d="M2968 978 c-9 -7 -37 -65 -62 -128 -73 -181 -223 -388 -433 -597
-121 -119 -133 -143 -93 -183 34 -34 72 -25 137 29 100 84 289 304 395 460 74
108 168 317 168 373 0 45 -74 75 -112 46z"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}
