import React, { Component, useState , useRef} from "react";
import HeaderSmall from "./menu/HeaderSmall";
import HeaderMain from "./menu/HeaderMain";
import Footer from "./components/footer";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
import "./contact.css";
import ButtonLoader from "./trainings/ButtonLoader";
import { Parallax } from "react-parallax";
import emailjs from '@emailjs/browser';

const fadeLeft = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
`;

const Form = () => {
  // States for registration
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading]  = useState(false);

  // States for checking the errors
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  // Handling the name change
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setSubmitted(false);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
    setSubmitted(false);
  };

  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setSubmitted(false);
  };

  // Handling the password change
  const handleQuery = (e) => {
    setQuery(e.target.value);
    setSubmitted(false);
  };

  // Handling the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (firstName === "" || lastName === "" || email === "" || query === "") {
      setError(true);
    } else {
      sendEmail(e);
    }
  };

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <p>Hey {firstName + " " + lastName} your message successfully sent!!</p>
      </div>
    );
  };

  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error ? "" : "none",
        }}
      >
        <p>Please enter all the fields</p>
      </div>
    );
  };
  const refForm = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs.sendForm('service_qv5jngs', 'template_mfb6b6b', refForm.current, 'Wwfx-Y2G5l9Qhig6w')
      .then((result) => {
          console.log(result.text);
          setError(false)
          setSubmitted(true)
          setLoading(false)
      }, (e) => {
          console.error(e.text);
          setError(true)
          setLoading(false)
      });
      e.target.reset();
  };
  return (
    <div className="form">
      <div>
        <h1>Let's Connect...</h1>
      </div>

      <form ref = {refForm} onSubmit= {sendEmail}>
        {/* Labels and inputs for form data */}
        {/* <label className="label">First Name</label> */}
        <input
          onChange={handleFirstName}
          className="input"
          value={firstName}
          type="text"
          name="from_name"
          placeholder="First Name"
        />

        {/* <label className="label">Last Name</label> */}
        <input
          onChange={handleLastName}
          className="input"
          value={lastName}
          type="text"
          placeholder="Last Name"
        />

        {/* <label className="label">Email</label> */}
        <input
          onChange={handleEmail}
          className="input"
          value={email}
          type="email"
          name="email"
          placeholder="Email"
        />

        {/* <label className="label">Message</label> */}
        <textarea
          onChange={handleQuery}
          className="input"
          value={query}
          type="text"
          name="message"
          
          placeholder="Message"
        />
        {/* Calling to the methods */}
      <div className="messages mt-1 mb-3">
        {
          error ? 
          <>{errorMessage()}</>:
          <>{successMessage()}</>
        }
      </div>
        <button
          onClick={handleSubmit}
          className="btn-main lead m-auto"
          type="submit"
        >
          {
            loading ?
             <>
             <i className="fa fa-refresh fa-spin spinner submitinside"/>
             </> :
             <><span className="submitinside">Submit</span></>
          }
        </button>
      </form>
    </div>
  );
};

var createReactClass = require('create-react-class');
const Maps = createReactClass({
  iframe: function () {
    return {
      __html: this.props.iframe
    }
  },

  render: function() {
    return (
      <div>
        <div dangerouslySetInnerHTML={ this.iframe() } />
      </div>
    );
  }
});

class Contact extends Component {
  render() {
    const imgStyle = {
      width: "150px",
      height: "150px",
    };
    
    const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.40166585048!2d-96.73947088543058!3d32.91398448429738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c1fee73400001%3A0x47ff73f2d26a5964!2s9319%20Lyndon%20B%20Johnson%20Fwy%20Suite%20116%2C%20Dallas%2C%20TX%2075243%2C%20USA!5e0!3m2!1sen!2sin!4v1662753015416!5m2!1sen!2sin" width="500" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    return (
      <>
        <Parallax
          bgImage={process.env.PUBLIC_URL + "/scroll/ph8.jpg"}
          strength={2000}
          bgImageStyle={{ height: "100vh", width: "100%" }}
        >
          <HeaderMain />
          {/*<div className="contact">
             <div className="mb-5 pb-5">
              <h1 className="mx-5 px-2"> Reason for inquiry</h1>
              <span>
                <center>Please select the purpose for your inquiry</center>
              </span>
            </div> 
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="nft_coll_pp">
                    <span>
                      <img
                        style={{ imgStyle }}
                        className="lazy"
                        src="https://img.icons8.com/external-flat-satawat-anukul/64/000000/external-communication-communication-flat-flat-satawat-anukul-28.png"
                        alt=""
                      />
                    </span>
                    
                  </div>
                  <div className="nft_coll_info">
                    <span onClick={() => window.open("/home", "_self")}>
                      <h4>Request A Demo</h4>
                    </span>
                    <span>
                      {" "}
                      Consequat occaecat ullamco amet non eiusmod nostrud dolore
                      irure incididunt est duis anim sunt officia. Fugiat velit
                      proident aliquip nisi incididunt nostrud exercitation
                      proident est nisi.
                    </span>
                    <div
                      className="btn-main lead m-auto my-5"
                    >
                     <a target="_blank" rel="nofollow" href='mailto:admin@cyberinfoinc.com?subject=Request A Demo : Cyber Info'>Request A Demo</a> 
                    </div>
                    
                  </div>
                </div>
                <div className="col">
                  <div className="nft_coll_pp">
                    <span>
                      <img
                        className="lazy"
                        src="https://img.icons8.com/external-icongeek26-outline-colour-icongeek26/64/000000/external-sales-due-diligence-icongeek26-outline-colour-icongeek26.png"
                        alt=""
                      />
                    </span>
                  
                  </div>
                  <div className="nft_coll_info">
                    <span>
                      <h4>Sales Inquiry</h4>
                    </span>
                    <span>
                      {" "}
                      Consequat occaecat ullamco amet non eiusmod nostrud dolore
                      irure incididunt est duis anim sunt officia. Fugiat velit
                      proident aliquip nisi incididunt nostrud exercitation
                      proident est nisi.
                    </span>
                    <div
                      className="btn-main lead m-auto my-5"
                    >
                      
                      <a target="_blank" rel="nofollow" href='mailto:admin@cyberinfoinc.com?subject=Sales Inquiry : Cyber Info'>Sales Inquiry</a> 

                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="nft_coll_pp">
                    <span>
                      <img
                        className="lazy"
                        src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-author-literature-flaticons-lineal-color-flat-icons-4.png"
                        alt=""
                      />
                    </span>
                  
                  </div>
                  <div className="nft_coll_info">
                    <span>
                      <h4>Customer Support</h4>
                    </span>
                    <span>
                      {" "}
                      Consequat occaecat ullamco amet non eiusmod nostrud dolore
                      irure incididunt est duis anim sunt officia. Fugiat velit
                      proident aliquip nisi incididunt nostrud exercitation
                      proident est nisi.
                    </span>
                    <div
                      className="btn-main lead m-auto my-5"
                    >
                     
                      <a target="_blank" rel="nofollow" href='mailto:admin@cyberinfoinc.com?subject=Customer Support : Cyber Info'>Customer Support</a> 
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>*/}
          <Reveal
            className="onStep"
            keyframes={fadeLeft}
            // delay={300}
            duration={800}
          >
            <div className="contact-info ">
              <div>
                <Form  />
              </div>
              <div className="map">
                <Maps iframe={iframe} />
              </div>
            </div>
          </Reveal>
          <Footer />
        </Parallax>
      </>
    );
  }
}

export default Contact;
